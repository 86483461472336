<template>
  <div class="wrapper">
    <fw-receipt :is_my='true' :receipt="receipt" @postMessage="postMessage" />
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  created() {
    if (this.$route.query.datastype == 1) {
      this.receipt = {
        type: 1, // 进入那个页面 1是列表页， 3是手工录入页
        source: "智能识票", // 来源
        title: "智能识票", // 标题
        editorMsgType: 1, // 流水类型:1 银行回单;2 承兑汇票
        editorMsg: null, // type = 3 时候，editorMsg有值就传递，没值就null
      };
    } else {
      this.receipt = {
        type: 3, // 进入那个页面 1是列表页， 3是手工录入页
        source: "手工录入", // 来源
        title: "手工录入", // 标题
        editorMsgType: 1, // 流水类型:1 银行回单;2 承兑汇票
        editorMsg: null, // type = 3 时候，editorMsg有值就传递，没值就null
      };
    }
  },
  mounted() {},
  data() {
    return {
      receipt: {
        type: 1, // 进入那个页面 1是列表页， 3是手工录入页
        source: "智能识票", // 来源
        title: "智能识票", // 标题
        editorMsgType: 1, // 流水类型:1 银行回单;2 承兑汇票
        editorMsg: null, // type = 3 时候，editorMsg有值就传递，没值就null
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    postMessage(val) {
      if (this.$route.query.datastype == 2) {
        console.log(val, 11111);
        let params = {
          ...val,
          department_id:0,
          department_name:'',
          division_id: 0, //部门id
          division_name: "", // 部门名称
          project_id: 0, //项目id
          project_name: "", //项目名称
        };
        if (!params.account_bank_id) {
          params.account_bank_id = 0;
        }
        if (!params.payment_nature) {
          params.payment_nature = '货款';
        }
        if (!params.payment_nature_id) {
          params.payment_nature_id = 0;
        }
        let api = "/ocr/personal_bank_receipt/submit_hand";

        this.$axios.postJSON(api, params).then(res => {
          if (res.code == 200) {
            this.$message.success("提交成功");
            this.$router.push({
              name: "我的订单 / 支付",
              params: { datas: res.data },
            });
          }
        });
        // if (val.amount != this.$route.query.order_amount) {
        //   return this.$message.warning("回单金额与应付金额不相等,请核对");
        // }
      } else {
        console.log(val);
        let arr = val.table;
        let flag1 = arr.some(v => v.log_id == 0);
        if (flag1) return this.$message.error("票据识别失败");
        let flag2 = arr.every(v => v.account_bank_id && v.account_bank_id > 0);
        if (!flag2) return this.$message.error("请完善回单明细的内容");
        let data = arr.map(v => {
          let obj = {
            log_id: v.log_id, // 识别日志id
            res: {
              // 编辑后的数据
              account_bank_id: v.account_bank_id || 0, // 银行账户id(account_bank表id)
              department_id: v.department_id, // 部门id
              department_name: v.department_name, //部门名称
              serial_number: v.serial_number, // 流水单号
              type: v.type, // 流水性质:1 收入;2 支出
              abstract: v.abstract, // 摘要
              amount: v.amount, //交易金额/票据金额
              big_amount: v.big_amount, //大写金额
              files: v.files, //源文件信息

              liushui_num: v.liushui_num, //流水单号/票号
              pay_bank: v.pay_bank, //付款人开户银行
              pay_name: v.pay_name, //付款人户名
              pay_number: v.pay_number, //付款人账号
              payee_bank: v.payee_bank, //收款人开户银行
              payee_name: v.payee_name, //收款人户名
              payee_number: v.payee_number, //收款人账号
              payment_nature: v.payment_nature || '货款', //款项性质名称
              payment_nature_id: v.payment_nature_id || 0, // 款项性质id
              receipt_num: v.receipt_num, //回单编号
              remark: v.remark, //备注
              title: v.title, //标题
              transaction_date: v.transaction_date, //交易日期/收票日期
              use_to: v.use_to, //用途
              division_id: 0, //部门id
              division_name: "", // 部门名称
              project_id: 0, //项目id
              project_name: "", //项目名称
            },
          };
          return obj;
        });
        let api = "/ocr/personal_bank_receipt/submit";
        let params = {
          data,
        };

        this.$axios.postJSON(api, params).then(res => {
          if (res.code == 200) {
            this.$message.success("提交成功");
            this.$router.push({
              name: "我的订单 / 支付",
              params: { arrs: res.data },
            });
          }
        });
        // if (data.amount != this.$route.query.order_amount) {
        //   return this.$message.warning("回单金额与应付金额不相等,请核对");
        // }

        // this.$router.push({
        //   name: "我的订单 / 支付",
        //   params: { datas: data },
        // });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  margin-bottom: 10px;
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  padding: 10px;
}
</style>
